<template>
  <div class="elv-create-project-plan-introduce-table-box">
    <div v-for="(item, index) in props.tableData" :key="index" class="elv-create-project-plan-introduce-table-item">
      <div class="elv-create-project-plan-introduce-header">{{ getLocaleShowInfo(item.title) }}</div>
      <div
        v-for="(moduleItem, moduleIndex) in item.list"
        :key="moduleIndex"
        class="elv-create-project-plan-introduce-content"
      >
        <div
          v-if="moduleItem.length"
          class="elv-create-project-plan-introduce-content-box"
          :class="{
            'elv-create-project-plan-introduce-content-box-try':
              moduleItem.length === 1 && moduleItem[0].en === 'Trial advanced modules'
          }"
        >
          <div
            v-for="(detailItem, detailIndex) in moduleItem"
            :key="detailIndex"
            class="elv-create-project-plan-introduce-content-item"
          >
            <span class="elv-create-project-plan-introduce-content-item-container"
              ><SvgIcon name="sources-sync-done" width="14" height="14" />
              <span class="elv-create-project-plan-introduce-content-item-title">{{
                getLocaleShowInfo(detailItem)
              }}</span></span
            >
            <div v-if="(detailItem as any)?.child" class="elv-create-project-plan-introduce-content-child">
              <span v-for="(childItem, childIndex) in (detailItem as any)?.child" :key="childIndex">
                {{ getLocaleShowInfo(childItem) }}
              </span>
            </div>
          </div>
        </div>
        <span v-else class="elv-create-project-plan-introduce-content-empty">-</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  tableData: {
    type: Array<any>,
    default: () => []
  }
})

const getLocaleShowInfo = computed(() => (data: any) => {
  const currentLanguage = useI18n().locale.value
  return currentLanguage === 'en' ? data.en : data.zh
})
</script>
<style lang="scss">
.elv-create-project-plan-introduce-table-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  font-family: 'Plus Jakarta Sans';
  border: 1px solid #edf0f3;
  border-radius: 3px;

  .elv-create-project-plan-introduce-table-item {
    width: 100%;
    border-top: 1px solid #edf0f3;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 236px 236px 236px;

    &:first-child {
      border-top: none;
    }

    .elv-create-project-plan-introduce-header {
      font-size: 12px;
      font-weight: 500;
      color: #1e2024;
      background-color: #f9fafb;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .elv-create-project-plan-introduce-content {
      width: 236px;
      height: 100%;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #636b75;
      padding: 16px;
      border-left: 1px solid #edf0f3;
      padding-right: 0px;

      .elv-create-project-plan-introduce-content-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        gap: 8px;

        .elv-create-project-plan-introduce-content-item {
          width: 100%;
          display: flex;
          flex-direction: column;
          position: relative;

          .elv-create-project-plan-introduce-content-item-container {
            width: 100%;
            position: relative;
            display: flex;

            .elv-create-project-plan-introduce-content-item-title {
              width: calc(100% - 18px);
              display: flex;
            }

            svg {
              margin-top: 2px;
              margin-right: 4px;
            }
          }

          .elv-create-project-plan-introduce-content-child {
            display: flex;
            flex-direction: column;
            width: calc(100% - 32px);
            margin-left: 32px;
            margin-top: 8px;
            gap: 8px;
            position: relative;

            span {
              display: flex;
              align-items: flex-start;
              font-size: 12px;
              line-height: 16px;
              position: relative;

              &::before {
                position: absolute;
                display: block;
                content: '';
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: #aaafb6;
                left: -12px;
                top: 4px;
              }
            }
          }
        }
      }

      .elv-create-project-plan-introduce-content-box-try {
        height: 100%;
        color: #636b75;

        svg {
          display: none;
        }
      }

      .elv-create-project-plan-introduce-content-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        color: #aaafb6;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
